export function tabs() {
    const tabs = document.querySelectorAll(".tabs-js");
    tabs.forEach(tab => {
        const nav = tab.querySelector(".b-tabs__nav");
        nav.addEventListener("click", e => {
            e.preventDefault();
            const target = e.target;
            if (target.className === "b-tabs__link") {
                const item = tab.querySelector(target.hash);
                const prevLink = nav.querySelector(".active");
                const prevItem = tab.querySelector(".show");

                target.classList.add("active");
                item.classList.add("show");

                prevLink.classList.remove("active");
                prevItem.classList.remove("show");
            }
        });
    });
}
